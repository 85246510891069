import "./index.scss";
import LocalCommunityCard from "../LocalCommunityCard";
import Hands from "assets/icons/Hands.png";
import Charity from "assets/icons/Charity.png";
import Heart from "assets/icons/Heart.png";
import Offering from "assets/icons/Offering.png";
import Shakehands from "assets/icons/Shakehands.png";
import Aeroplane from "assets/icons/Aeroplane.png";
import Slider from "react-slick";

const index = () => {
  const settings = {
    speed: 500,
    autoplay: true,
    slidesToShow: 5,
    rows: 1,
    slidesToScroll: 1,
    infinite: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          // rows: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          rows: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          rows: 3,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
          rows: 3,
        },
      },
    ],
  };

  return (
    <div className="local-community-section">
      <div className="container">
        <p className="heading">Empowering Local Communities</p>
        <Slider {...settings}>
          <div>
            <LocalCommunityCard trophy={Charity} text={"Civic Engagement"} />
          </div>
          <div>
            <LocalCommunityCard trophy={Hands} text={"Interfaith Harmony"} />
          </div>
          <div>
            <LocalCommunityCard
              trophy={Shakehands}
              text={"Youth Empowerment"}
            />
          </div>
          <div>
            <LocalCommunityCard trophy={Heart} text={"Health Care Programe"} />
          </div>
          <div>
            <LocalCommunityCard
              trophy={Offering}
              text={"Hunger Prevention Programe"}
            />
          </div>
          <div>
            <LocalCommunityCard trophy={Aeroplane} text={"Immigration"} />
          </div>
        </Slider>
        {/* <div className="columns">
          <div className="column"><LocalCommunityCard trophy={Charity} text={"Civic Engagement"} /></div>
          <div className="column"><LocalCommunityCard trophy={Hands} text={"Interfaith Harmony"} /></div>
          <div className="column"><LocalCommunityCard trophy={Shakehands} text={"Youth Empowerment"} /></div>
          <div className="column"><LocalCommunityCard trophy={Heart} text={"Health Care Programe"} /></div>
          <div className="column"><LocalCommunityCard trophy={Offering} text={"Hunger Prevention Programe"} /></div>
          <div className="column"><LocalCommunityCard trophy={Aeroplane} text={"Immigration"} /></div>
        </div> */}
      </div>
    </div>
  );
};

export default index;
