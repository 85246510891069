import { Hero, RoundImage, SimpleParagraph } from "components";
import BackgroundImage from "assets/images/round-bg-5.svg";
import Image from "assets/images/round-img-1.png";
import BackgroundImage2 from "assets/images/help-merchants.svg";
import Image2 from "assets/images/round-img-7.png";
import HeroBG from "assets/images/low-income-immigrants-bg.jpg";
import "./index.scss";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Low Income Immigrants"} backgroundImage={HeroBG} />
      <SimpleParagraph
        heading={
          <>
            Critical issue of{" "}
            <span style={{ color: "#EC3237" }}>affordable</span> housing
          </>
        }
        description={
          "Brooklyn Emerge has conducted extensive research on the critical issue of affordable housing in New York City. As the city continues to experience a rise in the cost of living, a significant proportion of low-to-moderate-income residents are facing difficulties in accessing suitable and affordable housing."
        }
      />
      <RoundImage
        heading={
          <>
            Educational and
            <br />
            Employment <span>opportunities</span>
          </>
        }
        description={
          "The scarcity of affordable housing can lead to a host of negative consequences, such as overcrowding, inadequate living conditions, and an increase in homelessness. These issues can have a detrimental effect on the overall well-being of individuals, including poor health outcomes, heightened crime rates, and diminished educational and employment opportunities. In light of this pressing issue."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"690px"}
        mMarginBottom={"210px"}
        tHeight={"530px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        heading={
          <>
            Testament to our{" "}
            <span style={{ color: "#3E4095" }}>commitment</span>
          </>
        }
        description={
          "This project serves as a testament to our commitment to addressing the pressing issues faced by the community and working towards a more equitable and just society for all`"
        }
      />
      <RoundImage
        description={
          "Brooklyn Emerge has undertaken a housing project aimed at providing affordable housing options to low-to-moderate-income residents in New York City. We understand the importance of addressing the housing crisis and the positive impact it can have on the wider community. Our public housing project aims to provide safe, decent, and affordable housing options to those who are most in need. By doing so, we hope to promote stability, improve health outcomes, and increase opportunities for education and employment. By providing affordable housing options, we are working towards creating a more equitable and just society for all New York City residents.`,"
        }
        image={Image2}
        bgImage={BackgroundImage2}
        mHeight={"710px"}
        mMarginBottom={"280px"}
        tHeight={"550px"}
        invert={true}
        tMarginBottom={"260px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
    </>
  );
};

export default Index;
