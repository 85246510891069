import "./index.scss";
import HeartHands from "assets/images/heart-hands.svg";

const index = () => {
  return (
    <div className="volunteer-form-section">
      <div className="k-row">
        <div className="left">
          <p className="heading">Become A Volunteer</p>
          <div className="form">
            <div className="form-row">
              <div className="form-input">
                <input type="text" placeholder="Your name " />
              </div>
              <div className="form-input">
                <input type="text" placeholder="your email " />
              </div>
            </div>
            <div className="form-input">
              <input type="text" placeholder="Contact number " />
            </div>
            <div className="form-input">
              <input type="text" placeholder="Address " />
            </div>

            <button className="submit-btn">Submit</button>
          </div>
        </div>
        <div className="right">
          <div>
            <img src={HeartHands} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
