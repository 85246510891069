import Globe from "assets/icons/Globe.png";
import LoveHand from "assets/icons/LoveHand.png";
import People from "assets/icons/People.png";
import Staff from "assets/icons/Staff.png";
import Hat from "assets/icons/Hat.png";
import "./index.scss";

const index = () => {
  return (
    <div className="figures-banner-section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="figure">
              <img src={Hat} alt="" />
              <p className="title">50+</p>
              <p className="sub-title">
                Revitalized SEDI
                <br />
                Entrepreneurs
              </p>
            </div>
          </div>
          <div className="column">
            <div className="figure">
              <img src={People} alt="" />
              <p className="title">36,938</p>
              <p className="sub-title">
                Kids engaged and
                <br />
                empowered
              </p>
            </div>
          </div>
          <div className="column">
            <div className="figure">
              <img src={Globe} alt="" />
              <p className="title">24,184</p>
              <p className="sub-title">
                people served
                <br />
                around the world
              </p>
            </div>
          </div>
          <div className="column">
            <div className="figure">
              <img src={LoveHand} alt="" />
              <p className="title">1,600</p>
              <p className="sub-title">
                U.S.-based
                <br />
                volunteers
              </p>
            </div>
          </div>
          <div className="column">
            <div className="figure">
              <img src={Staff} alt="" />
              <p className="title">124 STAFF</p>
              <p className="sub-title">
                ages 24
                <br />
                and younger
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
