import "./index.scss";
import RightArrow from "assets/icons/RightArrow.svg";

const index = ({ background, text, description }) => {
  return (
    <div
      className="our-programs-card"
      style={{ ...(!!background && { backgroundImage: `url(${background})` }) }}
    >
      <div className="column-direction">
        <div className="k-row">
          <p className="text">{text}</p>
          <img src={RightArrow} alt="" />
        </div>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default index;
