import "./index.scss";
import ImpactCard from "../ImpactCard";
import Bag from "assets/icons/Bag.svg";
import Bench from "assets/icons/Bench.svg";
import Broom from "assets/icons/Broom.svg";
import Brush from "assets/icons/Brush.svg";
import Crosswalk from "assets/icons/Crosswalk.svg";
import Path from "assets/icons/Path.svg";
import RestArea from "assets/icons/RestArea.svg";
import Shop from "assets/icons/Shop.svg";
import Team from "assets/icons/Team.svg";
import Wealth from "assets/icons/Wealth.svg";
import SalmanAfridi from "assets/images/salman-afridi.svg";
import { ReactComponent as DottedVector } from "assets/images/dotted-line.svg";
import { useMediaQuery } from "react-responsive";

const Index = () => {
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <div className="impacts-section">
      <p className="heading">Impacts</p>

      <div className="container">
        <div className="columns">
          <div className="column">
            <ImpactCard
              trophy={Broom}
              minHeight={"306px"}
              text={
                "Increased cleanliness in local neighborhood by recruiting volunteer leaders to conduct beautification projects in Spring 2023"
              }
            />
          </div>
          <div className="column">
            <ImpactCard
              trophy={Team}
              minHeight={"306px"}
              text={
                "Identified, developed, and launch commercial revitalization projects in partnership with local stakeholders."
              }
            />
          </div>
          <div className="column">
            <ImpactCard
              trophy={Path}
              minHeight={"306px"}
              text={
                "Revitalize the neighborhoods through aesthetic enhancements streetscape to encourage resident and visitor use of the area."
              }
            />
          </div>
          <div className="column">
            <ImpactCard
              trophy={Crosswalk}
              minHeight={"306px"}
              text={
                "Enhance pedestrian safety through crosswalk striping, bollards near intersections, and digital lighting on sidewalks."
              }
            />
          </div>
        </div>
        <br />
        <br />
        <div className="columns">
          <div className="column">
            <ImpactCard
              trophy={Wealth}
              trophyMarginLeft={"12%"}
              minHeight={"414px"}
              text={
                "With resources, expertise, and support from key stakeholders from the neighborhood carried out catalytic projects that advance their commercial revitalization efforts and build their economic development capacity."
              }
            />
          </div>
          <div className="column">
            <ImpactCard
              trophy={Shop}
              trophyMarginLeft={"12%"}
              minHeight={"414px"}
              text={
                "Encouraged merchants for storefront improvements, such as new signage, lighting, and awnings, as well as public realm improvements, such as wayfinding, vendor pop-up markets, and other interventions that boost foot traffic, increase sales, and contribute to a greater sense of safety."
              }
            />
          </div>
          <div className="column">
            <ImpactCard
              trophy={Bag}
              trophyMarginLeft={"12%"}
              minHeight={"414px"}
              text={
                "Developed business retention, attraction activities and physical improvements to storefronts and streetscapes in commercial corridors after identifying underinvested areas in both neighborhoods."
              }
            />
          </div>
        </div>
        <br />
        <br />
        <div className="columns">
          <div className="column">
            <ImpactCard
              trophy={RestArea}
              trophyMarginLeft={"12%"}
              minHeight={"414px"}
              text={
                "Promote cohesion through similar street furniture and design elements in the neighborhood area."
              }
            />
          </div>
          <div className="column">
            <ImpactCard
              trophy={Bench}
              trophyMarginLeft={"12%"}
              minHeight={"414px"}
              text={
                "Incorporated more seating and public amenities for visitors and residents to provide an additional level of comfort in the area."
              }
            />
          </div>
          <div className="column">
            <ImpactCard
              trophy={Brush}
              trophyMarginLeft={"12%"}
              minHeight={"414px"}
              text={"Hand-painted murals"}
            />
          </div>
        </div>
      </div>

      <div className="salman-afridi-quote">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds">
              <div className="quote">
                <p>
                  “Through our deep ties in these communities, Brooklyn Emerge
                  Inc. seeks to leverage its relationships with cultural,
                  spiritual and business leaders to form a strong merchant
                  association across these neighborhoods to contemplate, develop
                  and promote local assets. What I have observed that merchants
                  are not self-sustaining; they’re not able to adjust to the
                  fast-changing trends/environment. They must habit themselves
                  to the dazzle of the light and outward success. They need to
                  shift their gears in the quests for abundance, success comes
                  from within, not from without. With the support of SBS we have
                  helped merchants educate and elevate their business, use art
                  of sense marketing, improve their neighborhood, and help them
                  achieve their strategic goals imminently.”
                </p>
              </div>
            </div>
            <div className="column">
              <div className="k-column">
                <div>
                  <div className="image">
                    <img src={SalmanAfridi} alt="afridi" />
                  </div>
                  {!!!isTablet && <DottedVector />}
                </div>
                <p className="name">Salman Rehman Afridi</p>
                {!!isTablet && (
                  <p className="sub-name">Chief Operating Officer</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
