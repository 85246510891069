
import React, { useState } from 'react'
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Col, Form, Row } from 'react-bootstrap';
import './checkout.scss'

const PaymentForm = () => {

    const stripe = useStripe();
    const elements = useElements();
    const [pay, setPay] = useState(false)


    const handleSubmit = async (event) => {
        event.preventDefault();
        setPay(true)
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'https://brooklynemerge.org/',
            }
        });

        if (result.error) {
            console.log(result.error.message);
        }
    };



    return (
        <>

            <Form onSubmit={handleSubmit} className='payment_pay'>
                <Row>
                    <Col md={12}>
                        <div className="from_input">
                            <PaymentElement />
                        </div>
                        <Button type='submit' className="btn" disabled={pay}>Pay</Button>
                    </Col>
                </Row>
            </Form>


        </>
    )
}

export default PaymentForm