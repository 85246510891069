import { useMediaQuery } from "react-responsive";
import "./index.scss";

const Index = ({ trophy, text, minHeight, maxHeight, width }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const isTablet = useMediaQuery({
    query: "(max-width: 768px) and (min-width: 426px)",
  });

  console.log(isMobile, isTablet);

  return (
    <div className="impact-card">
      <div className="center">
        <div className="trophy">
          <img src={trophy} alt="trophy" />
        </div>
      </div>
      <div
        className="card"
        style={{
          ...(!!minHeight && { minHeight }),
          ...(!!maxHeight && { maxHeight }),
          ...(!!width && { width }),
        }}
      >
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Index;
