import {
  DonateNowForm,
  FiguresBanner,
  Hero,
  OurProgramsHero,
  OurProgramsSection,
} from "components";
import "./index.scss";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Our Programs"} />
      <OurProgramsHero />
      <FiguresBanner />
      <OurProgramsSection />
      <DonateNowForm showImg={true} />
    </>
  );
};

export default Index;
