import "./index.scss";
import { useMediaQuery } from "react-responsive";
import DesktopBackground from "assets/images/merchant-organizing.svg";
import MobileBackground from "assets/images/merchant-organizing-mobile.svg";
import Trophy from "assets/images/merchant-organizing-trophy.svg";
import Logo from "assets/images/merchant-organizing-logo.svg";

const Index = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const isTablet = useMediaQuery({
    query: "(max-width: 768px) and (min-width: 426px)",
  });

  return (
    <div
      className="merchant-organizing-section"
      style={{
        backgroundImage:
          isMobile || isTablet
            ? `url(${MobileBackground})`
            : `url(${DesktopBackground})`,
      }}
    >
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="left">
              {isMobile ? (
                <>
                  <p className="heading">Merchant Organizing</p>
                  <div className="logo">
                    <img width={"100%"} height={"100%"} src={Logo} alt="logo" />
                  </div>
                </>
              ) : (
                <>
                  <div className="logo">
                    <img width={"100%"} height={"100%"} src={Logo} alt="logo" />
                  </div>
                  <p className="heading">Merchant Organizing</p>
                </>
              )}
            </div>
          </div>
          <div className="column">
            <div className="right">
              <div className="center">
                <div className="trophy-badge">
                  <img
                    width={"100%"}
                    height={"100%"}
                    src={Trophy}
                    alt="trophy"
                  />
                </div>
              </div>
              <div className="card">
                Brooklyn Emerge was awarded{" "}
                <span>Strategic Impact Grant FY23</span> for organizing
                Merchants on Mcdonald Ave Little Bangladesh and Bath Avenue
                which are diversely filled with small business owners, they
                offer visitors unique character and charm. Restaurants offer
                everything from organic delights to casual cuisines, Uzbek,
                Bangali & Afghani cuisine to Southern elegance, healthy choices
                and strong cardamon tea.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
