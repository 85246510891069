import {
  AnnouncementBanner,
  ImpactsSection,
  MerchantOrganizingBanner,
  RoundImage,
  SimpleParagraph,
} from "components";
import Image1 from "assets/images/strategic-impact-1.svg";
import BackgroundImage1 from "assets/images/strategic-impact.svg";
import Image2 from "assets/images/help-merchants-1.svg";
import BackgroundImage2 from "assets/images/help-merchants.svg";
import "./index.scss";
import MerchantWindows from "sections/MerchantWindows";
import BlueCards from "sections/BlueCards";
import BannerWithTextOverlay from "sections/BannerWithTextOverlay";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MerchantOrganizingBanner />
      <div className="top-spacing">
        <AnnouncementBanner
          heading={
            <>
              “Building on <span>Community Strength</span>,”
            </>
          }
          description={
            "Which represents our core belief that the most effective way to improve a community is to develop and promote local assets, including people, businesses and organizations."
          }
        />
      </div>
      <RoundImage
        heading={
          <>
            Strategic Impact <br />
            <span style={{ color: "#EC3237" }}>Grant FY23</span>
          </>
        }
        description={
          "The mission of Brooklyn Emerge (B.E) was to build partnerships with businesses, government, and other community stakeholders to provide civic and economic opportunities to merchants. We leverage our relationships with cultural, spiritual and business leaders and formed two merchant associations on both neighborhoods."
        }
        image={Image1}
        bgImage={BackgroundImage1}
        mHeight={"650px"}
        mMarginBottom={"250px"}
        tHeight={"520px"}
        tMarginBottom={"200px"}
      />
      <div className="top-spacing-2">
        <RoundImage
          heading={
            <>
              Help merchants
              <br />
              To elevate
            </>
          }
          description={
            "Our vision is to preserve Bath Avenue, and Church/Mcdonald Avenue in Central and South Brooklyn as a desirable neighborhood for owning businesses, growing economic potential, and attracting investments."
          }
          image={Image2}
          bgImage={BackgroundImage2}
          invert={true}
          mHeight={"550px"}
          mMarginBottom={"280px"}
          tHeight={"480px"}
          tMarginBottom={"200px"}
        />
      </div>
      <SimpleParagraph
        heading={
          <>
            Connecting{" "}
            <span style={{ color: "#EC3237" }}>Merchant Organizing</span> to the
            Needs of the
            <span style={{ color: "#EC3237" }}>Commercial Corridor</span>
          </>
        }
        description={
          "We have gone extra mile to expand our horizon by forming is to give a voice to and advocate on behalf of merchants benefit and B.E work is dedicated to the development and promotion of new businesses, sustain existing merchants and civic action. We have also built strong ties with merchants in the neighborhood."
        }
      />
      <MerchantWindows />
      <ImpactsSection />
      <BannerWithTextOverlay
        text={
          <p>
            B.E worked block by block to{" "}
            <span style={{ color: "#EC3237" }}>empower merchants</span> to
            become active in improving their businesses, fully involved in its
            revitalization.
          </p>
        }
      />
      <BlueCards />
    </>
  );
};

export default Index;
