import "./Hero.scss";

const index = ({ text = "Some text here", backgroundImage }) => {
  return (
    <>
      <div
        className="page-header"
        style={{
          ...(!!backgroundImage && {
            backgroundImage: `url(${backgroundImage})`,
          }),
        }}
      >
        <div className="k-row">
          <h1 className="heading">{text}</h1>
        </div>
      </div>
    </>
  );
};

export default index;
