import { DonateNowForm, Hero, RoundImage, SimpleParagraph } from "components";
import BackgroundImage from "assets/images/round-bg-3.svg";
import Image from "assets/images/round-img-4.png";
import "./index.scss";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Donate Now"} />
      <SimpleParagraph
        heading={
          <>
            Volunteering with{" "}
            <span style={{ color: "#EC3237" }}>Brooklyn Emerge</span> (B.E) is a
            great way
            <br />
            to give back to your{" "}
            <span style={{ color: "#3E4095" }}>community</span>
          </>
        }
        style={{ marginBottom: "60px", marginTop: "60px" }}
      />
      <RoundImage
        heading={
          <>
            Why <span style={{ color: "#EC3237" }}>Donate</span> with us?
          </>
        }
        description={
          "Donating to Brooklyn Emerge (B.E) is an investment in the sustainability and vitality of the neighborhoods we serve. Your support will help us to continue our mission of providing comprehensive support to the community, including working with immigrants and refugees, preventing speculation and displacement of small businesses, and fostering collaborative partnerships among merchants to drive commercial revitalization. With your contribution, we can continue to make a positive impact in neighborhoods A and B, and empower individuals and families to thrive in their new homes. By donating to B.E, you are not only supporting the immediate needs of the community but also investing in long-term solutions that will benefit the entire community. Your donation will make a real difference in the lives of those we serve and help to sustain the vitality of the neighborhoods."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"950px"}
        mMarginBottom={"280px"}
        tHeight={"670px"}
        tMarginBottom={"200px"}
      />
      <DonateNowForm />
    </>
  );
};

export default Index;
