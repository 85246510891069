import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage from "assets/images/round-bg-11.svg";
import Image from "assets/images/round-img-14.png";
import HeroBG from "assets/images/immigration.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Immigration"} backgroundImage={HeroBG} />
      <SimpleParagraph
        heading={
          <>
            <span style={{ color: "#EC3237" }}>Brooklyn Emerge</span> is
            committed to addressing the community's pressing issues
            <br /> and working towards creating a more{" "}
            <span style={{ color: "#3E4095" }}>equitable and just</span> society
            for all.
          </>
        }
      />
      <RoundImage
        heading={
          <>
            Brooklyn Emerge recognizes
            <br />
            the critical issue
          </>
        }
        description={
          "of affordable housing in New York City and has undertaken a housing project to provide affordable options to low-to-moderate-income residents. The scarcity of affordable housing leads to negative consequences such as overcrowding, inadequate living conditions and homelessness, which affects individuals' overall well-being."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"620px"}
        mMarginBottom={"200px"}
        tHeight={"510px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        heading={<>This initiative aligns with SDG 11</>}
        description={
          <>
            Sustainable Cities and Communities, which aims to make cities and
            human settlements inclusive, safe,
            <br />
            resilient, and sustainable.
            <br />
            Brooklyn Emerge is committed to addressing the community's pressing
            issues and working towards
            <br />
            creating a more equitable and just society for all.
          </>
        }
      />
    </>
  );
};

export default Index;
