import "./MerchantWindows.scss";
import RelationshipLogo from "assets/icons/Relationship.svg";
import AssociationLogo from "assets/icons/Association.svg";
import { useMediaQuery } from "react-responsive";

const MerchantWindows = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <>
        {!isMobile &&
            <div className="merchant-windows-section">
                <div className="merchant-windows-content windows-content-1">
                    <div className="content-wrapper">
                        <img src={RelationshipLogo} alt="Alliance" />
                        <h3>Alliance</h3>
                        <p>B.E initiated collaborative partnership an alliance among merchants from multiple sectors working together to achieve a common purpose. The collaborative partnerships attempt to improve conditions and outcomes related to the businesses, foster partnerships and build local capacity to steward ongoing commercial revitalization that is benefited by the entire communities.</p>
                        <p>Such partnerships are often hybrid strategies that may include aspects of planning, community organizing, community development, policy advocacy, and acting as a catalyst for revitalization, capacity building and foster partnerships.</p>
                    </div>
                </div>
                <div className="merchant-windows-img windows-img-1"></div>
                <div className="merchant-windows-img windows-img-2"></div>
                <div className="merchant-windows-content windows-content-2">
                    <div className="content-wrapper">
                        <img src={AssociationLogo} alt="Association" />
                        <h3>Association</h3>
                        <p>Little Bangladesh & Bath Avenue Merchants Association: has a strong understanding of diverse storefront business owners’ needs, challenges, and desires. Finding enthusiastic business owners about implementing improvements to the neighborhood that will gladly participate.</p>
                        <p>and promoted local assets, including people, businesses and organizations. We aimed to have both neighborhoods as a desirable for owning businesses, growing economic potential, and attracting investments.</p>
                    </div>
                </div>
            </div>
        }

        {isMobile &&
        <div className="merchant-windows-section">
            <div className="window-wrapper merchant-windows-img windows-img-1">
                <div className="window-title">
                    <div className="title-wrapper">
                        <img src={RelationshipLogo} alt="Alliance" />
                        <h3>Alliance:</h3>
                    </div>
                </div>
                <div className="window-content">
                    <p>B.E initiated collaborative partnership an alliance among merchants from multiple sectors working together to achieve a common purpose. The collaborative partnerships attempt to improve conditions and outcomes related to the businesses, foster partnerships and build local capacity to steward ongoing commercial revitalization that is benefited by the entire communities.</p>
                    <p>Such partnerships are often hybrid strategies that may include aspects of planning, community organizing, community development, policy advocacy, and acting as a catalyst for revitalization, capacity building and foster partnerships.</p>
                </div>
            </div>

            <div className="window-wrapper merchant-windows-img windows-img-2">
                <div className="window-title">
                    <div className="title-wrapper">
                        <img src={AssociationLogo} alt="Association" />
                        <h3>Association:</h3>
                    </div>
                </div>
                <div className="window-content">
                    <p>Little Bangladesh & Bath Avenue Merchants Association: has a strong understanding of diverse storefront business owners’ needs, challenges, and desires. Finding enthusiastic business owners about implementing improvements to the neighborhood that will gladly participate.</p>
                    <p>and promoted local assets, including people, businesses and organizations. We aimed to have both neighborhoods as a desirable for owning businesses, growing economic potential, and attracting investments.</p>
                </div>
            </div>
        </div>
        }
        </>
    );
};

export default MerchantWindows;
