import "./index.scss";

const index = ({ heading, description }) => {
  return (
    <div className="simple-paragraph-section">
      <div className="container">
        {!!heading && <div className="heading">{heading}</div>}
        {!!description && <div className="description">{description}</div>}
      </div>
    </div>
  );
};

export default index;
