import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage from "assets/images/round-bg-10.svg";
import Image from "assets/images/round-img-13.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Muslim Coalition"} />
      <SimpleParagraph
        heading={
          <>
            Brooklyn Emerge, as a{" "}
            <span style={{ color: "#EC3237" }}>
              Community-Based Organization
            </span>
            (CBO)
            <br /> is a private{" "}
            <span style={{ color: "#3E4095" }}>non-profit entity</span>
          </>
        }
      />
      <RoundImage
        description={
          "Brooklyn Emerge is an organization that works to empower the community in New York City by providing education, engagement, and opportunities for empowerment. The organization aims to expand and shape the perception of what it means to be a Muslim in the city by utilizing civic education and leadership development. To achieve this, Brooklyn Emerge focuses on forming strong partnerships with other faith-based communities, advocating for the importance of citizen engagement, empowering individuals to bring about change, and nurturing future leaders."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"660px"}
        mMarginBottom={"200px"}
        tHeight={"500px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        description={
          "The organization's Muslim coalition programs are centered on developing leadership skills, educating citizens on civic matters, and promoting cross-cultural understanding."
        }
      />
    </>
  );
};

export default Index;
