import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage1 from "assets/images/round-bg-9.svg";
import BackgroundImage2 from "assets/images/round-bg-17.svg";
import Image1 from "assets/images/round-img-11.png";
import Image2 from "assets/images/round-img-12.png";
import HeroBG from "assets/images/halal-meal-share.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Halal Meal Share"} backgroundImage={HeroBG} />
      <SimpleParagraph
        heading={
          <>
            <span style={{ color: "#EC3237" }}>Brooklyn Emerge</span> is taking
            a comprehensive approach
            <br />
            to address food insecurity in the community through its{" "}
            <span style={{ color: "#3E4095" }}>Hunger Prevention</span> Plan
          </>
        }
      />
      <RoundImage
        description={
          "The plan includes a Halal meal-share program, which provides hot and nutritious meals to individuals in need, and a Public Refrigerator program that installs refrigerators in various neighbor hoods for community members to share excess food. Additionally, community gardens are established in all neighborhoods to provide fresh produce and promote environmental sustainability. "
        }
        image={Image1}
        bgImage={BackgroundImage1}
        mHeight={"520px"}
        mMarginBottom={"280px"}
        tHeight={"430px"}
        tMarginBottom={"260px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <RoundImage
        description={
          "These interventions aim to alleviate hunger and food insecurity, empower individuals to access healthy nutritious food and promote environmental sustainabili The organization is committed to providing a range of services that address various community needs ncluding education and empowerment for marginalizec groups. "
        }
        image={Image2}
        bgImage={BackgroundImage2}
        invert={true}
        mHeight={"520px"}
        mMarginBottom={"200px"}
        tHeight={"410px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        description={
          "By aligning with the United Nations Sustainable Development Goals, specifically SDG 2, Brooklyn Emerge's Hunger Prevention Plan is making a tangible impact in the community and providing a blueprint for other organizations to follow in the fight against hunger and food insecurity."
        }
      />
    </>
  );
};

export default Index;
