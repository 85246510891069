import { Hero, RoundImage, SimpleParagraph } from "components";
import BackgroundImage from "assets/images/round-bg-1.svg";
import Image from "assets/images/round-img-1.png";
import "./index.scss";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Community Building Organization"} />
      <SimpleParagraph
        heading={
          <>
            Brooklyn Emerge, as a{" "}
            <span style={{ color: "#EC3237" }}>
              Community-Based Organization
            </span>{" "}
            (CBO)
            <br />
            is a private{" "}
            <span style={{ color: "#3E4095" }}>non-profit entity</span>
          </>
        }
      />
      <RoundImage
        heading={
          <>
            Educational and
            <br />
            Employment <span>opportunities</span>
          </>
        }
        description={
          "Brooklyn has been proven to be effective in serving its community by offering educational and other related services to individuals within the community. As part of its effort to reach out to prospective members citywide in a culturally-sensitive manner, "
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"590px"}
        mMarginBottom={"280px"}
        tHeight={"480px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        heading={
          <>
            Testament to our{" "}
            <span style={{ color: "#3E4095" }}>commitment</span>
          </>
        }
        description={
          "Brooklyn Emerge has established partnerships with other CBOs. Currently, Brooklyn Emerge is working closely with a number of CBOs to ensure that all New Yorkers, especially immigrants, have access to health care through the program."
        }
      />
    </>
  );
};

export default Index;
