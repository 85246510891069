import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage from "assets/images/round-bg-15.svg";
import Image from "assets/images/round-img-18.png";
import HeroBG from "assets/images/translation-services.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Translation Services"} backgroundImage={HeroBG} />
      <SimpleParagraph
        heading={
          <>
            At <span style={{ color: "#EC3237" }}>Brooklyn Emerge</span>, we
            believe that reading and
            <br />
            literacy are essential components of{" "}
            <span style={{ color: "#3E4095" }}>
              personal and community growth
            </span>
          </>
        }
      />
      <RoundImage
        description={
          "Our innovative literacy programs aim to transform the way we approach these subjects, by providing the new immigrant and underserved communities with the tools they need to succeed.This includes language training, translation services, and educational resources designed to help them understand their rights and improve their lives.By working to bring about systemic change."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"520px"}
        mMarginBottom={"200px"}
        tHeight={"440px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        description={
          <>
            We hope to <strong>empower these communities</strong> and help them
            to reach their full potential.
          </>
        }
      />
    </>
  );
};

export default Index;
