import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage from "assets/images/round-bg-13.svg";
import Image from "assets/images/round-img-16.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Digital Literacy"} />
      <SimpleParagraph
        heading={
          <>
            Brooklyn Emerge, as a{" "}
            <span style={{ color: "#EC3237" }}>
              Community-Based Organization
            </span>{" "}
            (CBO)
            <br />
            is a private{" "}
            <span style={{ color: "#3E4095" }}>non-profit entity</span>
          </>
        }
      />
      <RoundImage
        description={
          "At Brooklyn Emerge, we believe that reading and literacy are essential components of personal and community growth.Our innovative literacy programs aim to transform the way we approach these subjects, by providing the new immigrant and underserved communities with the tools they need to succeed.This includes language training, translation services, and educational resources designed to help them understand their rights and improve their lives."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"590px"}
        mMarginBottom={"200px"}
        tHeight={"460px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        description={
          <>
            We hope to <strong>empower these communities</strong> and help them
            to reach their full potential.
          </>
        }
      />
    </>
  );
};

export default Index;
