import "./index.scss";
import Slider from "react-slick";
import HomeLocationLogo from "assets/images/HomeLocationLogo.png";
import HomeNYCLogo from "assets/images/HomeNYCLogo.png";
import Prev from "assets/icons/BackArrow.png";
import Next from "assets/icons/NextArrow.png";
import Report from "assets/05-ImapctReport(Brooklyn).pdf";

const HomepageMainSlider = () => {
  const settings = {
    // arrows: true,
    adaptiveHeight: false,
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
    nextArrow: <img src={Next} alt="" />,
    prevArrow: <img src={Prev} alt="" />,
  };

  return (
    <div className="homepage-slider">
      <Slider {...settings}>
        <div className="slide1">
          <div className="container">
            <div className="slide-content">
              <h1>
                We are <br className="heading-br-mob" />
                <span className="color-red">One Brooklyn</span>
                <br />{" "}
                <span className="text-small">We are Brooklyn Emerge</span>
              </h1>
              <p>
                We Educate & Advocate for a more just society a strong and
                vibrant brooklyn through collaboration and mutual understanding
              </p>
              <a href={Report} className="rounded-button" download>
                Download Impact Report
              </a>
            </div>
          </div>
        </div>

        <div className="slide2">
          <div className="container">
            <div className="slide-content">
              <h2>Halal Meal Share Program</h2>
              <p>Serving 24,427 Meals per year</p>
            </div>
          </div>
        </div>

        <div className="slide3">
          <div className="container">
            <div className="slide-content">
              <div className="columns">
                <div className="column is-two-thirds">
                  <h2>Merchants Organizing</h2>
                  <p className="body1">
                    Brooklyn Emerge was awarded
                    <br />
                    <span>Strategic Impact Grant FY23</span>
                  </p>
                  <p className="body2">
                    For organizing Merchants on Mcdonald Ave Little Bangladesh &
                    Bath Avenue to form strong Merchants associations across
                    Brooklyn. B.E motto is, “Building on Community Strength,”
                    which represents our core belief.
                  </p>
                </div>
                <div
                  className="column nyc-logos"
                >
                  <div>
                    <img src={HomeNYCLogo} alt="" />
                  </div>
                  <div>
                    <img src={HomeLocationLogo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HomepageMainSlider;
