import { useMediaQuery } from "react-responsive";
import "./index.scss";
import Slider from "react-slick";

const Index = () => {
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const settings = {
    speed: 500,
    arrows: false,
    autoplay: true,
    slidesToShow: 2,
    rows: 1,
    slidesToScroll: 1,
    infinite: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="core-values-section">
      <div className="container">
        <p className="heading">Core Values</p>
        {isTablet ? (
          <div className="community-section-slider">
            <Slider {...settings}>
              <div>
                <p className="title">Equity</p>
                <p className="description">
                  Fair and impartial distribution of resources and
                  opportunities, striving to bridge the gap between marginalized
                  communities and mainstream society.
                </p>
              </div>
              <div>
                <p className="title">Collaboration</p>
                <p className="description">
                  Collective efforts and partnerships, engaging diverse groups
                  to achieve common goals and promote community cohesion
                </p>
              </div>
              <div>
                <p className="title">Empowerment</p>
                <p className="description">
                  Empowering marginalized individuals to take control of their
                  lives and realize their full potential
                </p>
              </div>
              <div>
                <p className="title">Inclusivity</p>
                <p className="description">
                  Diversity, inclusivity, recognizing the unique needs and
                  perspectives of different communities
                </p>
              </div>
              <div>
                <p className="title">Social Justice</p>
                <p className="description">
                  Equity and human rights, striving to eradicate systemic
                  oppression and discrimination in all formS.
                </p>
              </div>
            </Slider>
          </div>
        ) : (
          <div className="columns">
            <div className="column">
              <p className="title">Equity</p>
              <p className="description">
                Fair and impartial distribution of resources and opportunities,
                striving to bridge the gap between marginalized communities and
                mainstream society.
              </p>
            </div>
            <div className="column">
              <p className="title">Collaboration</p>
              <p className="description">
                Collective efforts and partnerships, engaging diverse groups to
                achieve common goals and promote community cohesion
              </p>
            </div>
            <div className="column">
              <p className="title">Empowerment</p>
              <p className="description">
                Empowering marginalized individuals to take control of their
                lives and realize their full potential
              </p>
            </div>
            <div className="column">
              <p className="title">Inclusivity</p>
              <p className="description">
                Diversity, inclusivity, recognizing the unique needs and
                perspectives of different communities
              </p>
            </div>
            <div className="column">
              <p className="title">Social Justice</p>
              <p className="description">
                Equity and human rights, striving to eradicate systemic
                oppression and discrimination in all formS.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
