import "./index.scss";

const index = ({ trophy, text }) => {
  return (
    <div className="local-community-card">
      <div className="center">
        <div className="trophy">
          <img src={trophy} alt="" />
        </div>
      </div>
      <div className="content">
        <p className="text">{text}</p>
      </div>
    </div>
  );
};

export default index;
