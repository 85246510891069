import "./BlueCards.scss";

const BlueCards = () => {
    return (
        <div className="container blue-cards-section">
            <div className="columns">
                <div className="column blue-card-wrapper">
                    <h4>Vendor Pop Up Market</h4>
                    <p>We hosted an expanded vendor pop-up market in March 2023 to satisfy retail needs in both neighborhoods, giving exposure to local businesses, and test a model for activating unused spaces.</p>
                </div>
                <div className="column blue-card-wrapper">
                    <h4>Eid Festival</h4>
                    <p>In march Ramadan festival will be held where merchants especially restaurants will have the opportunity to activate their brand, increase footfall by distributing flyers and attract new customers by marketing exceptionally...</p>
                </div>
                <div className="column blue-card-wrapper">
                    <h4>Storefront Improvement</h4>
                    <p>Storefront Improvements: To attract customers to existing or new businesses a new sign, awning and exterior lighting will be encouraged to install to allow for greater transparency.</p>
                </div>
                <div className="column blue-card-wrapper">
                    <h4>Graffiti Removal</h4>
                    <p>We identified areas vandalized with graffiti and require cleaning. Many businesses took on power washing efforts and work closely to cover roll down gates with beautiful murals.</p>
                </div>
            </div>
        </div>
    );
}

export default BlueCards;
