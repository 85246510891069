import "./index.scss";
import Checked from "assets/icons/Checked.svg";

const index = () => {
  return (
    <div className="checklist-section">
      <div className="k-row">
        <div className="left">
          <div className="box">
            By establishing and sustaining a culture of voting among voters,
            both old and new, we are working to give our communities a stronger
            political voice, so that the issues we care about are heard by
            politicians and elected officials.
          </div>
        </div>
        <div className="right">
          <div className="list">
            <div className="point">
              <img src={Checked} alt="Checked" />
              <p>
                Encouraging a more cooperative environment between diverse
                communities and organizations',
              </p>
            </div>
            <br />
            <div className="point">
              <img src={Checked} alt="Checked" />
              <p>
                Establishing a stronger institutional presence for Muslims in
                America',
              </p>
            </div>
            <br />
            <div className="point">
              <img src={Checked} alt="Checked" />
              <p>Providing sound information to the public',</p>
            </div>
            <br />
            <div className="point">
              <img src={Checked} alt="Checked" />
              <p>Making America a better place for all'</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
