import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage from "assets/images/round-bg-11.svg";
import Image from "assets/images/round-img-17.png";
import HeroBG from "assets/images/interfaith-harmony.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Interfaith Harmony"} backgroundImage={HeroBG} />
      <SimpleParagraph
        heading={
          <>
            This intervention is rooted in the principle of
            <br />
            <span style={{ color: "#EC3237" }}>
              "Love of the Good,
            </span> and{" "}
            <span style={{ color: "#3E4095" }}>Love of the Neighbor"</span>
          </>
        }
      />
      <RoundImage
        description={
          "Emphasizing acceptance and support for all individuals, regardless of their religious beliefs or lack thereof. Our program works towards building stronger ties between different faith and non-faith groups by organizing various events. By bringing together diverse communities, we aim to educate and promote understanding of common values to foster peace and harmony in their neighborhoods."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"520px"}
        mMarginBottom={"200px"}
        tHeight={"430px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        heading={
          <em>
            "Through this intervention, we align with{" "}
            <span style={{ color: "#EC3237" }}>SDG 16: Peace</span>, Justice and
            Strong Institutions by promoting social cohesion, non-violent
            conflict resolution, and fostering inclusivity and diversity."{" "}
          </em>
        }
      />
    </>
  );
};

export default Index;
