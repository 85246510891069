import {
  AnnouncementBanner,
  DonateNowForm,
  Hero,
  RoundImage,
} from "components";
import Image1 from "assets/images/strategic-impact-1.svg";
import BackgroundImage1 from "assets/images/strategic-impact.svg";
import Image2 from "assets/images/help-merchants-1.svg";
import BackgroundImage2 from "assets/images/help-merchants.svg";
import Love from "assets/icons/Love.svg";
import "./index.scss";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text="About Us" />
      <AnnouncementBanner
        trophy={Love}
        description={
          "Brooklyn Emerge was created in 2019 and works in all the areas of social development and initiatives through our various interventions and thematic areas. B.E has majorly achieved in community development in a sustainable manner. B.E has also equally focused on various other themes like mobilizing and engaging Muslim communities, business development, hunger prevention, education, environment, skill development and others like capacity building. To assist and empower underprivileged both Muslim and non-Muslim Americans through sustainable social service programs."
        }
      />
      <RoundImage
        heading={<>Who we are?</>}
        description={
          "TBrooklyn Emerge initially created as a civic engagement. Launched a number of development projects co-operation with local governments and community centers, like food prevention programs like halal hot meals and food pantry, good governance for board development, philanthropy development with businesses, mapping and training umbrella organizations. Since government responses proved largely insufficient or ineffective in addressing growing social problems, private benevolent societies and self-help organizations the predecessors of modern social service agencies played increasing roles in this regard which brought us here to enhance and promote better livelihood."
        }
        image={Image1}
        bgImage={BackgroundImage1}
        mHeight={"780px"}
        mMarginBottom={"-150px"}
        tHeight={"600px"}
        tMarginBottom={"200px"}
      />
      <div className="top-spacing">
        <RoundImage
          heading={<>What we do?</>}
          description={
            "Although B.E was created in 2019, we reached roughly ~600 New Yorkers every week through our programming. (Quick blurb on food distribution efforts). As a result, most engagements by the organization include collaboration with various business owners. In addition, we have connected residents and merchants with pro-bono and/or low-cost immigration services by partnering with local attorneys. Our deep reach in the community is demonstrated by coverage in popular local ethnic media for the Pakistani, Uzbek, Bangali, Afghani and Indian community."
          }
          image={Image2}
          bgImage={BackgroundImage2}
          invert={true}
          mHeight={"710px"}
          mMarginBottom={"280px"}
          tHeight={"550px"}
          tMarginBottom={"200px"}
        />
      </div>
      <DonateNowForm />
    </>
  );
};

export default Index;
