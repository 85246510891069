import {
  CoreValuesSection,
  DonateNowForm,
  FiguresBanner,
  LocalCommunitySection,
  NewsCenter,
  OurProgramsSection,
} from "components";
import "./Home.scss";
import React, { useEffect } from "react";
import HomepageMainSlider from "sections/HomepageMainSlider";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  const history = useNavigate();
  useEffect(() => {
    if (location.search != "") {
      history("/");
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="home">
      <HomepageMainSlider />
      <div className="overlap-new-center-section">{/* <NewsCenter /> */}</div>

      <div id="our-mission">
        <img alt="" src="/banners/ourMissionBG.png" className="bg-overlay" />
        <div className="our-mission-content">
          <h2>
            <span>Our</span>
            <br />
            Mission
          </h2>
          <div className="our-mission-text">
            <p>
              To empower and elevate the prospects of marginalized individuals
              and communities through equitable access to education, resources,
              and advocacy. By fostering a society that is characterized by
              equitable and just values, we aim to achieve a world where all
              individuals have the opportunity to thrive and prosper to their
              fullest potential.
            </p>

            {/* <a href="/" className="explore-more">
              Explore More
            </a> */}
          </div>
        </div>
      </div>
      {/* Our Mission */}

      <div id="our-vision">
        <img alt="" src="/banners/ourVisionBG.png" className="bg-overlay" />
        <div className="our-vision-content">
          <div className="our-vision-text">
            <p>
              To foster a vibrant and equitable community by providing all
              individuals with equitable access to resources and opportunities,
              enabling them to achieve their optimal potential. We are committed
              to promoting inclusivity, diversity, and social justice, and
              measuring our success by the positive impact we have on the lives
              of those we serve
            </p>

            {/* <a href="/" className="explore-more">
              Explore More
            </a> */}
          </div>

          <h2>
            <span>Our</span>
            <br />
            Vision
          </h2>
        </div>
      </div>

      <div className="cstm-top-bottom-margin">
        <CoreValuesSection />
      </div>
      <LocalCommunitySection />
      <OurProgramsSection />
      <FiguresBanner />
      <DonateNowForm showImg={true} />
    </div>
  );
};

export default Home;
