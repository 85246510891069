import "./BannerWithTextOverlay.scss";


const BannerWithTextOverlay = ({ text }) => {
    return (
        <div className="banner-text-overlay">
            <div className="container">
                <div className="banner-text-overlay-content">
                    {text}
                </div>
            </div>
        </div>
    );
}

export default BannerWithTextOverlay;
