import "./index.scss";

const index = ({ heading, description, trophy }) => {
  return (
    <div className="announcement-banner container">
      {!!trophy && (
        <div className="center">
          <div className="trophy">
            <img src={trophy} alt="badge" />
          </div>
        </div>
      )}
      <div
        className="content"
        style={{ ...(!!trophy && { paddingTop: "65px" }) }}
      >
        {!!heading && <p className="quote">{heading}</p>}
        {!!description && <p className="description">{description}</p>}
      </div>
    </div>
  );
};

export default index;
