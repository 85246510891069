import { Checklist, Hero, RoundImage, SimpleParagraph } from "components";
import BackgroundImage from "assets/images/round-bg-6.svg";
import Image from "assets/images/round-img-2.png";
import HeroBG from "assets/images/civic-engagement-bg.png";
import "./index.scss";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Civic Engagement"} backgroundImage={HeroBG} />
      <SimpleParagraph
        description={
          <>
            This program is designed to <strong>empower rural women</strong> and
            new immigrants in New York City, who often
            <br />
            face ongoing inequalities that limit their access to{" "}
            <strong>services and civic participation</strong>
          </>
        }
      />
      <RoundImage
        heading={
          <>
            Civic Engagement
            <br />
            (ACE)
          </>
        }
        description={
          "The scarcity of affordable housing can lead to a host of negative consequences, such as overcrowding, inadequate living conditions, and an increase in homelessness. These issues can have a detrimental effect on the overall well-being of individuals, including poor health outcomes, heightened crime rates, and diminished educational and employment opportunities. In light of this pressing issue."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"640px"}
        mMarginBottom={"280px"}
        tHeight={"535px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <Checklist />
    </>
  );
};

export default Index;
