import "./index.scss";
import OurProgramsCard from "../OurProgramsCard";
import Img1 from "assets/images/OurPrograms/program-1.svg";
import Img2 from "assets/images/OurPrograms/program-2.svg";
import Img3 from "assets/images/OurPrograms/program-3.svg";
import Img4 from "assets/images/OurPrograms/program-4.svg";
import Img5 from "assets/images/OurPrograms/program-5.svg";
import Img6 from "assets/images/OurPrograms/program-6.svg";
import Img7 from "assets/images/OurPrograms/program-7.svg";
import Img8 from "assets/images/OurPrograms/program-8.svg";
import Img9 from "assets/images/OurPrograms/program-9.svg";
import Img10 from "assets/images/OurPrograms/program-10.svg";
import Img11 from "assets/images/OurPrograms/program-11.svg";
import { Link } from "react-router-dom";

const index = () => {
  console.log(`react snippet works!`);

  return (
    <div className="our-programs-new-section">
      <div className="container">
        <div className="columns">
          <div className="column"><Link to="/low-income-immigrants"><OurProgramsCard background={Img1} text={<>Low Income<br/>Immigrants</>} description={"Assisl immigrants and refugees by providing a safe living Environment ..."} /></Link></div>
          <div className="column"><Link to="/civic-engagement"><OurProgramsCard background={Img2} text={<>Civic<br/>Engagement</>} description={"Educate and empower new immigrants and rural women ..."} /></Link></div>
          <div className="column"><Link to="/youth-empowerment"><OurProgramsCard background={Img3} text={<>Youth<br/>Empowerment</>} description={"Entrepreneurs providing business support, incubation, acceleration ..."} /></Link></div>
        </div>
        <div className="columns">
          <div className="column"><Link to="/health-care-program"><OurProgramsCard background={Img4} text={<>Health Care<br/>Program</>} description={"Communities through collaboration with healthcare service providers ..."} /></Link></div>
          <div className="column"><Link to="/halal-meal-share"><OurProgramsCard background={Img5} text={<>Halal Meal<br/>Share</>} description={"Addressing food insecurity through halal meal-share. public refrigerator ..."} /></Link></div>
          <div className="column"><Link to="/muslim-coalition"><OurProgramsCard background={Img6} text={<>Muslim<br/>Coalition</>} description={"Brooklyn Emerge is an organization that works to empower the ..."} /></Link></div>
        </div>
        <div className="columns">
          <div className="column"><Link to="/immigration"><OurProgramsCard background={Img7} text={<>Immigration</>} description={"Assisls immigrants and refugees by providing a safe living Environment ..."} /></Link></div>
          <div className="column"><Link to="/education-for-all"><OurProgramsCard background={Img8} text={<>Education<br/>for All</>} description={"Provides free primary and secondary education through a ..."} /></Link></div>
          <div className="column"><Link to="/digital-literacy"><OurProgramsCard background={Img9} text={<>Digital<br/>Literacy</>} description={"At Brooklyn Emerge, we believe that reading and literacy are essential ..."} /></Link></div>
        </div>
        <div className="columns last-row">
          <div className="column is-one-third"><Link to="/interfaith-harmony"><OurProgramsCard background={Img10} text={<>Interfaith<br/>Harmony</>} description={"Promotes interfaith harmony emphasi zing the importance of ..."} /></Link></div>
          <div className="column is-one-third"><Link to="/translation-services"><OurProgramsCard background={Img11} text={<>Translation<br/>Services</>} description={"At Brooklyn Emerge, we believe that reading and literacy are essential ..."} /></Link></div>
        </div>
        {/* <div className="columns is-variable is-1">
          <div className="column hide-column"></div>
          <div className="column add-right-space"><Link to="/interfaith-harmony"><OurProgramsCard background={Img10} text={<>Interfaith<br/>Harmony</>} description={"Promotes interfaith harmony emphasi zing the importance of ..."} /></Link></div>
          <div className="column"><Link to="/translation-services"><OurProgramsCard background={Img11} text={<>Translation<br/>Services</>} description={"At Brooklyn Emerge, we believe that reading and literacy are essential ..."} /></Link></div>
          <div className="column hide-column"></div>
        </div> */}
      </div>
    </div>
  );
};

export default index;
