import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage1 from "assets/images/round-bg-8.svg";
import BackgroundImage2 from "assets/images/round-bg-16.svg";
import Image1 from "assets/images/round-img-9.png";
import Image2 from "assets/images/round-img-10.png";
import HeroBG from "assets/images/health-care-program.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Health Care Program"} backgroundImage={HeroBG} />
      <SimpleParagraph
        heading={
          <>
            <span style={{ color: "#3E4095" }}>Brooklyn Emerge</span> is
            committed to ensuring the
            <br />
            <span style={{ color: "#EC3237" }}>health and well-being</span> of
            the community
          </>
        }
      />
      <RoundImage
        description={
          "Brooklyn Emerge is committed to the health and well-being of the community,and our comprehensive healthcare program is designed to various medical needs of the community. Through collaboration with insurance providers, we offer access to essential healthcareservices, including vaccinations,testing, education and awareness campaigns.Our goal is to provide accessible and affordable healthcare services to all members of the community and we aim to help individuals determine eligibility for low-cost or no-cost insurance coverage."
        }
        image={Image1}
        bgImage={BackgroundImage1}
        mHeight={"660px"}
        mMarginBottom={"280px"}
        tHeight={"480px"}
        tMarginBottom={"240px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <RoundImage
        description={
          "As part of our commitment to the SDG 3 goal of ensuring healthy lives and promoting well-being, we are dedicated to providing the best possible care and support to mitigate the impact of the .andemic on the communi ."
        }
        image={Image2}
        bgImage={BackgroundImage2}
        invert={true}
        mHeight={"420px"}
        mMarginBottom={"280px"}
        tHeight={"360px"}
        tMarginBottom={"240px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
    </>
  );
};

export default Index;
