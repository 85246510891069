import "./index.scss";
import { useMediaQuery } from "react-responsive";

const Index = ({
  heading,
  description,
  image,
  invert = false,
  bgImage,
  mHeight,
  mMarginBottom,
  tHeight,
  tMarginBottom,
  cardBgColor,
  cardTextColor,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const isTablet = useMediaQuery({
    query: "(max-width: 768px) and (min-width: 426px)",
  });

  return (
    <div
      className="hero-circle-section"
      style={{
        backgroundImage: `url(${bgImage})`,
        ...(isMobile && { height: mHeight, marginBottom: mMarginBottom }),
        ...(isTablet && { height: tHeight, marginBottom: tMarginBottom }),
      }}
    >
      <div className="columns">
        {invert && !isTablet && !isMobile ? (
          <>
            <div className="column center">
              <div className="round-image-section">
                <div className="rotating-image" />
                <div
                  className="static-image"
                  style={{ backgroundImage: `url(${image})` }}
                />
              </div>
            </div>
            <div className="column center">
              <div
                className="card"
                style={{
                  ...(cardBgColor && {
                    background: cardBgColor,
                    // mixBlendMode: "normal",
                    // filter: "blur(50px)",
                  }),
                  ...(cardTextColor && { color: cardTextColor }),
                }}
              >
                {!!heading && <p className="header">{heading}</p>}
                <br />
                <p className="description">{description}</p>
              </div>
            </div>
          </>
        ) : invert && !!isTablet && !!isMobile ? (
          <>
            <div className="column center">
              <div
                className="card"
                style={{
                  ...(cardBgColor && {
                    background: cardBgColor,
                    // mixBlendMode: "normal",
                    // filter: "blur(50px)",
                  }),
                  ...(cardTextColor && { color: cardTextColor }),
                }}
              >
                {!!heading && <p className="header">{heading}</p>}
                <br />
                <p className="description">{description}</p>
              </div>
            </div>
            <div className="column center">
              <div className="round-image-section">
                <div className="rotating-image" />
                <div
                  className="static-image"
                  style={{ backgroundImage: `url(${image})` }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="column center">
              <div
                className="card"
                style={{
                  ...(cardBgColor && {
                    background: cardBgColor,
                    // mixBlendMode: "normal",
                    // filter: "blur(50px)",
                  }),
                  ...(cardTextColor && { color: cardTextColor }),
                }}
              >
                {!!heading && <p className="header">{heading}</p>}
                <br />
                <p className="description">{description}</p>
              </div>
            </div>
            <div className="column center">
              <div className="round-image-section">
                <div className="rotating-image" />
                <div
                  className="static-image"
                  style={{ backgroundImage: `url(${image})` }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
