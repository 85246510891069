import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage from "assets/images/round-bg-11.svg";
import Image from "assets/images/round-img-15.png";
import HeroBG from "assets/images/education-for-all.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Education For All"} backgroundImage={HeroBG} />
      <SimpleParagraph
        heading={
          <>
            At <span style={{ color: "#EC3237" }}>Brooklyn Emerge</span>, we
            believe that
            <br />
            every child deserves access to{" "}
            <span style={{ color: "#3E4095" }}>primary and secondary</span>{" "}
            education.
          </>
        }
      />
      <RoundImage
        heading={
          <>
            Educational and
            <br />
            Employment opportunities
          </>
        }
        description={
          "Through our Education for All program we have established a network of schools that are funded by charitable donations. Our schools provide high-quality education at no cost to the students, ensuring equal opportunities for all children to receive a comprehensive education. We are committed to breaking down barriers to education and providing a pathway to success for all students, regardless of their background or financial situation."
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"710px"}
        mMarginBottom={"200px"}
        tHeight={"560px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        heading={
          <>
            Pathway to <span style={{ color: "#EC3237" }}>Success</span>
          </>
        }
        description={
          <>
            We are committed to breaking down barriers to education and
            providing a pathway to success for all
            <br />
            students, regardless of their background or financial situation.
          </>
        }
      />
    </>
  );
};

export default Index;
