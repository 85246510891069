import "./index.scss";
import HeartHands from "assets/images/heart-hands.svg";
import DonateNowCircleImage from "assets/images/donate-now-form-image.png";
import React, { useState, useEffect } from "react";
import { ReactComponent as DollarSign } from "assets/icons/Dollar.svg";
import { ReactComponent as USD } from "assets/icons/USD.svg";
import StripeContainer from "components/Payment/StripeContainer";
import axios from "axios";

const FormDonate = ({ showImg = false }) => {
  const [amount, setAmount] = useState(0);
  const [pay, setPay] = useState(false);
  const [client, setClient] = useState({});

  const paymentIntent = async () => {
    if (amount != 0) {
      const res = await axios
        .post(
          "https://api.stripe.com/v1/payment_intents",
          {
            amount: amount + "00",
            currency: "usd",
            payment_method_types: ["card"],
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization:
                "Bearer sk_test_51NuKcKHHMWlVfaJLNrab0W6uW1dObs7BFW4ndxwBrEz562ZEvR4Oa5Cub88ByN62cyHzLXlqLCmErEtcRn7IN9EH00cFVN413s",
            },
          }
        )
        .then((res) => res);

      if (res && res?.data?.client_secret) {
        setClient(res?.data);
        setPay(true);
      }
    }
  };

  return (
    <div className="donate-now-form">
      {showImg && (
        <div className="container">
          <p className="title">Donate Now</p>
          <p className="sub-title">
            Donating to Brooklyn Emerge (B.E) is an investment in the
            sustainability and
            <br />
            vitality of the neighborhoods we serve.
          </p>
        </div>
      )}

      <div className="k-row">
        <div className="left">
          <div>
            <img src={HeartHands} alt="" />
          </div>
          <div>
            <div className="donate-sticker-row">
              <div className="donate-sticker" onClick={() => setAmount(10)}>
                <p className="text">Donate</p>
                <p className="amount">$ 10</p>
              </div>
              <div className="donate-sticker" onClick={() => setAmount(25)}>
                <p className="text">Donate</p>
                <p className="amount">$ 25</p>
              </div>
              <div className="donate-sticker" onClick={() => setAmount(100)}>
                <p className="text">Donate</p>
                <p className="amount">$ 100</p>
              </div>
              <div className="donate-sticker" onClick={() => setAmount(200)}>
                <p className="text">Donate</p>
                <p className="amount">$ 200</p>
              </div>
            </div>
            <br />
            {!pay ? (
              <div className="donate-payment-section">
                <div className="payment-input">
                  <DollarSign />
                  <input
                    type="text"
                    placeholder="Other Amount"
                    value={amount}
                  />
                  <USD />
                </div>
                <button className="payment-btn" onClick={paymentIntent}>
                  Continue to Secure Payment
                </button>
              </div>
            ) : (
              <StripeContainer client={client} />
            )}
          </div>
        </div>
        <div className="right">
          {showImg ? (
            <>
              <img src={DonateNowCircleImage} alt="" />
            </>
          ) : (
            <>
              <p className="heading">Donate now</p>
              <p className="description-text">
                Donating to Brooklyn Emerge (B.E) is an investment in the
                sustainability and vitality of the neighborhoods we serve. Your
                support will help us to continue our mission of providing
                comprehensive support to the community, including working with
                immigrants and refugees, preventing speculation and displacement
                of small businesses, and fostering collaborative partnerships
                among merchants to drive commercial revitalization.
                <br />
                <br />
                With your contribution, we can continue to make a positive
                impact in neighborhoods A and B, and empower individuals and
                families to thrive in their new homes. By donating to B.E, you
                are not only supporting the immediate needs of the community,
                but also investing in long-term solutions that will benefit the
                entire community. Your donation will make a real difference in
                the lives of those we serve and help to sustain the vitality of
                the neighborhoods.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormDonate;
