import "./index.scss";
import Image1 from "assets/images/our-programs-hero-1.svg";
import Image2 from "assets/images/our-programs-hero-2.svg";

const index = () => {
  return (
    <div className="our-programs-hero-section">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column">
            <div className="pictures">
              <div className="left">
                <div className="image">
                  <img src={Image1} alt="" />
                </div>
              </div>
              <div className="right">
                <div className="image">
                  <img src={Image2} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p className="heading">
                <span>Diversifying</span> the Concept of Community{" "}
                <span>Building!</span>
              </p>
              <p className="description">
                Brooklyn Emerge initially created as a civic engagement.
                Launched a number of development projects co-operation with
                local governments and community centers, like food prevention
                programs like halal hot meals and food pantry, good governance
                for board development, philanthropy development with businesses,
                mapping and training umbrella organizations. Since government
                responses proved largely insufficient or ineffective in
                addressing growing social problems, private benevolent societies
                and self-help organizations the predecessors of modern social
                service agencies played increasing roles in this regard which
                brought us here to enhance and promote better livelihood.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
