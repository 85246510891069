import { Hero, RoundImage, SimpleParagraph, VolunteerForm } from "components";
import BackgroundImage from "assets/images/round-bg-3.svg";
import Image from "assets/images/round-img-3.png";
import "./index.scss";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Volunteer With Us"} />
      <SimpleParagraph
        heading={
          <>
            <span style={{ color: "#3E4095" }}>Unlocking</span> Economic
            Potential in{" "}
            <span style={{ color: "#EC3237" }}>Central Brooklyn</span>
          </>
        }
        description={
          <>
            Volunteering with Brooklyn Emerge (B.E) is a great way to give back
            to your community and make a<br />
            positive impact on the lives of local residents and businesses. B.E
            is committed to creating economic
            <br />
            security for all New Yorkers by connecting them to good jobs,
            creating stronger businesses, and
            <br />
            building a fairer economy in neighborhoods across the five boroughs.
          </>
        }
      />
      <RoundImage
        heading={
          <>
            Why <span style={{ color: "#EC3237" }}>Volunteer</span> with us?
          </>
        }
        description={
          <>
            As a volunteer with B.E, you will have the opportunity to work on a
            variety of projects that support our mission. From working with
            local businesses to improve their operations, to helping residents
            access educational and career opportunities, there are many ways to
            get involved. If you're interested in community development, you can
            help us create conditions that allow local businesses to grow and
            thrive.
            <br />
            <br />
            This can include working on projects that beautify commercial
            corridors, advocate for public and private investments, and attract
            new businesses to the area.
          </>
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"790px"}
        mMarginBottom={"240px"}
        tHeight={"580px"}
        tMarginBottom={"200px"}
      />
      <VolunteerForm />
    </>
  );
};

export default Index;
