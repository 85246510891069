import { Hero, RoundImage, SimpleParagraph } from "components";
import "./index.scss";
import BackgroundImage from "assets/images/round-bg-7.svg";
import Image from "assets/images/round-img-8.png";
import HeroBG from "assets/images/youth-empowerment-bg.png";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero text={"Youth Empowerment"} backgroundImage={HeroBG} />
      <SimpleParagraph
        heading={
          <>
            <span style={{ color: "#EC3237" }}>Empowering youth</span> through
            support in college & career, readiness,
            <br /> leaders club, fostering entrepreneurship, civic education.
          </>
        }
      />
      <RoundImage
        description={
          "Brooklyn Emerge is committed to promoting youth empowerment by providing resources and tools to youth from marginalized community. BE provide a supportive and dynamic ecosystem that help SEDI entrepreneurs overcome barriers to success and build thriving businesses that contribute to the economic development. Empowering youth through support in college & career, readiness, leaders club, fostering entrepreneurship, civic education. We prioritize individual and collective assets,capabilities to promote community cohesion and collaboration. "
        }
        image={Image}
        bgImage={BackgroundImage}
        mHeight={"660px"}
        mMarginBottom={"200px"}
        tHeight={"510px"}
        tMarginBottom={"200px"}
        cardBgColor={"transparent"}
        cardTextColor={"#ffffff"}
      />
      <SimpleParagraph
        description={
          "Our focus on social empowerment aims to empower marginalized groups, including immigrant, women, and minority communities to engage in community decision-making and contribute to shaping a more equitable and just society. Through our Youth Empowerment, BE is contributing towards achievement of SDG 8: Decent Work and Economic Growth and SDG: 10 Reduced Inequalities. "
        }
      />
    </>
  );
};

export default Index;
